<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
            <photo-swipe
                ref="pswp"
                :items="photosModel"
                :options="{
                    closeOnScroll: false
                }"
            ></photo-swipe>

			<ej-breadcrumb>
				<li class="breadcrumb-item">
					<router-link :to="{name: 'orders'}">Pedidos</router-link>
				</li>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<main class="content">
				<div class="container-fluid" v-if="spinner">
					<div class="row d-flex justify-content-center">
						<div class="default-spinner">
							<div class="default-spinner-inner">
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>

				<div class="container-fluid" v-else>
					<div class="row">
						<div class="col">
							<h5>Informações do {{ orderName }}</h5>
						</div>
					</div>

					<div class="row">
                        <div class="form-group col-lg-2 col-12" v-if="operation === 'create'">
                            <label>ID</label>
                            <input
                                type="text"
                                v-model.trim="order.id"
                                class="form-control"
                            /> <!-- fixme EXCLUIR -->
                        </div>

						<div class="form-group col-lg-2 col-12">
							<label>Tipo*</label>
							<ej-select
								v-model.trim="type"
								:options="types"
								track-by="value"
								label="name"
							></ej-select>
						</div>

						<div class="form-group col-lg-2 col-12">
							<label>Data*</label>
							<ej-date-picker
								v-model.trim="order.date"
							></ej-date-picker>
						</div>

						<div class="form-group col-lg-3 col-12">
							<label>Cliente*</label>
							<ej-select
								v-model.trim="customer"
								:options="customers"
								track-by="id"
								:custom-label="customerCustomLabel"
								@search-change="customerAsyncFind"
								:loading="customerFinding"
							></ej-select>
						</div>

                        <div class="form-group col-lg-2 col-12">
                            <label>Status <font-awesome-icon v-tooltip="'Para alterar o status do pedido, acesse o item \'Ações\' na página de listagem dos pedidos.'" :icon="['far', 'question-circle']"></font-awesome-icon></label>
                            <ej-select
                                v-model.trim="status"
                                :options="statuses"
                                track-by="value"
                                label="name"
                                :disabled="operation !== 'create'"
                            ></ej-select>
                        </div>

						<div class="form-group col-lg-3 col-12">
							<label>Contato <font-awesome-icon v-tooltip="'Pessoa responsável pelo pedido/orçamento, na qual será feito contato caso necessário.'" :icon="['far', 'question-circle']"></font-awesome-icon></label>
							<ej-select
								v-model.trim="contact"
								:options="contacts"
								track-by="id"
								label="name"
								@search-change="contactAsyncFind"
								:loading="contactFinding"
							></ej-select>
						</div>

						<div class="form-group col-lg-3 col-12">
							<label>Revenda</label>
							<ej-select
								v-model.trim="reseller"
								:options="resellers"
								track-by="id"
                                :custom-label="resellerCustomLabel"
								@search-change="resellerAsyncFind"
								:loading="resellerFinding"
							></ej-select>
						</div>

						<div
							v-if="canUpdateSeller"
							class="form-group col-lg-3 col-12"
						>
							<label>Emitente*</label>
							<ej-select
								v-model.trim="seller"
								:options="sellers"
								track-by="id"
								:custom-label="sellerCustomLabel"
								@search-change="sellerAsyncFind"
								:loading="sellerFinding"
							></ej-select>
						</div>

						<div class="form-group col-lg-3 col-12" v-if="order.type === 'other'">
							<label>Lista de preços*</label>
							<ej-select
								v-model.trim="price_list"
								:options="price_lists"
								track-by="value"
								label="name"
							></ej-select>
						</div>

						<div class="form-group col-lg-3 col-12" v-if="order.type === 'other'">
							<label>Atividade*</label>
							<ej-select
								v-model.trim="activity"
								:options="activities"
								track-by="value"
								label="name"
							></ej-select>
						</div>

                        <div class="form-group col-lg-2 col-12">
                            <label>N° OC</label>
                            <input
                                type="text"
                                v-model.trim="order.po"
                                class="form-control"
                            />
                        </div>

                        <div class="form-group col-lg-2 col-12" v-if="order.type === 'cabin'">
                            <label>Num. Série</label>
                            <input
                                type="text"
                                :value="orderMetaModel['serie']"
                                class="form-control"
                                disabled
                            />
                        </div>
					</div>

					<!-- Se for Cabina -->
					<div v-if="_.get(order,'type') === 'cabin'">
						<br>

						<div class="row">
							<div class="col">
								<h5>Informações do Equipamento Agrícola</h5>
							</div>
						</div>

						<div class="row">
							<div class="form-group col-lg-2 col-12">
								<label>Equipamento*</label>
								<ej-select
									v-model.trim="category"
									:options="categories"
									track-by="id"
									label="name"
								></ej-select>
							</div>

							<div class="form-group col-lg-4 col-12">
								<label>Série*</label>
								<ej-select
                                    :value="equipment"
									:options="equipments"
									track-by="id"
									label="name"
									:loading="equipmentFinding"
									:disabled="!category"
                                    @select="equipmentSelected"
								></ej-select>
							</div>

							<div class="form-group col-lg-2 col-12">
								<label>Modelo</label>
								<input
									v-model.trim="orderMetaModel['equipamento.modelo']"
									type="text"
									class="form-control"
								>
							</div>

							<div class="form-group col-lg-2 col-12">
								<label>Motor</label>
								<ej-select
									v-model.trim="motor"
									:options="motors"
								></ej-select>
							</div>

							<div class="form-group col-lg-2 col-12">
								<label>Cor*</label>
								<ej-select
									v-model.trim="orderMetaModel['equipamento.cor']"
									:options="colors"
								></ej-select>
							</div>

							<div class="form-group col-lg-2 col-12">
								<label>Ano*</label>
								<input
									v-model.trim="orderMetaModel['equipamento.ano']"
									type="number"
									class="form-control"
								>
							</div>

							<div class="form-group col-lg-auto col-12">
								<label>Tem tanque inferior?</label><br>
                                <ej-toggle-button
                                    v-model.trim="orderMetaModel['equipamento.tanque_inferior']"
                                    :labels="{checked: 'Sim', unchecked: 'Não'}"
                                    :disabled="tanqueInferiorDesabilitado"
                                />
							</div>

							<div class="form-group col-lg-auto col-12">
								<label>Tem tanque superior?</label><br>
                                <ej-toggle-button
                                    v-model.trim="orderMetaModel['equipamento.tanque_superior']"
                                    :labels="{checked: 'Sim', unchecked: 'Não'}"
                                    :disabled="tanqueInferiorDesabilitado"
                                />
							</div>

							<div class="form-group col-lg-auto col-12">
								<label>Tem tanque traseiro?</label><br>
                                <ej-toggle-button
                                    v-model.trim="orderMetaModel['equipamento.tanque_traseiro']"
                                    :labels="{checked: 'Sim', unchecked: 'Não'}"
                                    :disabled="tanqueInferiorDesabilitado"
                                />
							</div>

							<div class="col-lg-4 col-12 d-flex flex-wrap">
								<div class="form-group mr-3">
									<label>É turbinado?</label><br>
									<ej-toggle-button
										v-model.trim="orderMetaModel['equipamento.turbinado']"
										:labels="{checked: 'Sim', unchecked: 'Não'}"
									/>
								</div>

								<div class="form-group mr-3">
									<label>Tem concha?</label><br>
									<ej-toggle-button
										v-model.trim="orderMetaModel['equipamento.concha']"
										:labels="{checked: 'Sim', unchecked: 'Não'}"
									/>
								</div>

								<div class="form-group mr-3">
									<label>É traçado?</label><br>
									<ej-toggle-button
										v-model.trim="orderMetaModel['equipamento.tracado']"
										:labels="{checked: 'Sim', unchecked: 'Não'}"
									/>
								</div>
                            </div>

                            <div class="form-group col-12">
                                <label>Fotos:*</label>
                                <div class="row">
                                    <div
                                        class="col-xl-2 col-lg-3 col-md-6 col-12 bloco-foto"
                                        :key="index"
                                        v-for="(foto, index) in photosModel"
                                    >
                                        <div
                                            class="foto"
                                            :style="{backgroundImage: `url(\'${foto.msrc}\')`}"

                                        >
                                            <div class="overlay">
                                                <button @click="openPhoto(index)">
                                                    <font-awesome-icon size="3x" :icon="['fas', 'search-plus']"></font-awesome-icon>
                                                </button>

                                                <button class="excluir" @click="excluiFoto(index)">
                                                    <font-awesome-icon size="2x" :icon="['fas', 'times-circle']"></font-awesome-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" style="margin-top: 10px">
                                    <div class="col-12">
                                        <ej-dropzone
                                            v-model="photosModel"
                                            :multiple="true"
                                        ></ej-dropzone>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>

					<br>

					<!-- Se não for Cabina -->
					<div class="row">
						<div class="col">
							<h5>Itens*</h5>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<table class="desktop-list-content with-sub-table">
								<thead>
									<tr>
										<th style="width: 25%">Produto</th>
										<th style="width: 07%">Qtd.</th>
										<th style="width: 13%">Valor unit.</th>
										<th style="width: 12%">Desconto</th>
										<th style="width: 11%">Op. Fiscal</th>
										<th style="width: 07%">Estoq.</th>
										<th style="width: 10%">Custo</th>
                                        <th style="width: 09%">Total</th>
										<th style="width: 06%"></th>
									</tr>
								</thead>
								<tbody>
									<tr :key="index" v-for="(item, index) in itemsBrute">
										<td colspan="9">
											<table>
												<tbody>
													<tr class="item">
														<td style="width: 25%">
															<ej-select
																v-if="order.type === 'cabin'"
																:custom-label="productCustomLabel"
																:value="itemsBruteModel[index].product"
																@select="itemProductSelected(index, $event)"
																@remove="itemProductRemoved(index)"
																:options="compatibleProducts"
																placeholder="Buscar produto por nome"
																:loading="compatibleProductsFinding"
																track-by="id"
															></ej-select>
<!--															v-model.trim="itemsBruteModel[index].product"-->

															<ej-select
																v-else
																:custom-label="productCustomLabel"
																v-model.trim="itemsBruteModel[index].product"
																:options="products"
																placeholder="Buscar produto por nome"
																:loading="productsFinding"
																track-by="id"
																@search-change="productAsyncFind"
															></ej-select>
														</td>

														<td style="width: 07%">
															<input type="number" class="form-control" v-model.trim="itemsBruteModel[index].quantity">
														</td>

														<td style="width: 13%">
															<currency-input v-model.trim="itemsBruteModel['['+index+'].price']" type="text" class="form-control"/>
														</td>

														<td style="width: 12%">
															<currency-input
																v-model.trim="itemsBruteModel[index].discount"
																type="text"
																class="form-control"
															/>
														</td>

														<td style="width: 11%">
															<ej-select
																v-model="itemsBruteModel['['+index+'].meta.tax_operation']"
																:options="tax_operations"
																track-by="value"
																label="name"
                                                                :disabled="order.type === 'cabin'"
															></ej-select>
														</td>

														<td style="width: 07%">
															<ej-button
                                                                class="btn btn-primary"
                                                                @click="viewStockAndCost(index)"
                                                                :disabled="order.type === 'cabin'"
                                                            >
																<template v-if="itemsBruteModel['['+index+'].loadingStock']">
																	<font-awesome-icon icon="spinner" pulse />
																</template>

																<template v-else>
																	<template v-if="itemsBruteModel['['+index+'].product.stock']">
																		{{ itemsBruteModel['['+index+'].product.stock'] }}&nbsp;<font-awesome-icon :icon="['fas','sync-alt']"></font-awesome-icon>
																	</template>

																	<font-awesome-icon v-else :icon="['fas','sync-alt']"></font-awesome-icon>
																</template>
															</ej-button>
														</td>

														<td style="width: 10%">
															<ej-button
                                                                class="btn btn-primary"
                                                                @click="viewStockAndCost(index)"
                                                                :disabled="order.type === 'cabin'"
                                                            >
																<template v-if="itemsBruteModel['['+index+'].loadingCost']">
																	<font-awesome-icon icon="spinner" pulse />
																</template>

																<template v-else>
																	<template v-if="itemsBruteModel['['+index+'].product.cost']">
																		{{ itemsBruteModel['['+index+'].product.cost'] }}&nbsp;<font-awesome-icon :icon="['fas','sync-alt']"></font-awesome-icon>
																	</template>

																	<font-awesome-icon v-else :icon="['fas','sync-alt']"></font-awesome-icon>
																</template>
															</ej-button>
														</td>

                                                        <td style="width: 09%">
                                                            {{ toCurrency((itemsBruteModel[index].price * itemsBruteModel[index].quantity) - itemsBruteModel[index].discount) }}
                                                        </td>

														<td style="width: 06%">
															<ej-button class="btn btn-primary" @click="removeItem(index)" v-if="order.type !== 'cabin'">
																<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
															</ej-button>
														</td>
													</tr>

													<tr class="characteristics">
														<td colspan="9">
															<div v-if="_.get(itemsBruteModel[index],'product.type') === 'cabin'" class="d-flex flex-wrap">
																<div class="form-group mr-4">
																	<label>Cor*</label>
																	<ej-select
																		v-model="itemsBruteModel[`${index}.meta.opcionais.cor`]"
																		:options="colors"
																	></ej-select>
																</div>

																<div class="form-group mr-4">
																	<label>Esguichos</label><br>
																	<ej-select
																		:value="esguichosOptions.find((e) => e.name === itemsBruteModel[`${index}.meta.opcionais.esguichos`])"
                                                                        @change="(e) => itemsBruteModel[`${index}.meta.opcionais.esguichos`] = e.name"
                                                                        track-by="name"
                                                                        label="name"
																		:options="esguichosOptions"
																	></ej-select>
																</div>

																<div class="form-group mr-4">
																	<label>Sistema de Som</label><br>
																	<ej-select
																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.sistema_som`]"
																		:options="['Sem Rádio', 'Com Rádio']"
                                                                        :disabled="sistemaSomDesabilitado"
																	></ej-select>
																</div>

																<div class="form-group mr-4">
																	<label>Filtro<ej-asterisk v-if="!filtroDesabilitado"></ej-asterisk></label><br>
																	<ej-select
																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.filtro`]"
																		:options="['Anti-Pólen', 'Carvão Ativado']"
                                                                        :disabled="filtroDesabilitado"
																	></ej-select>
																</div>

																<div class="form-group mr-4">
																	<label>I-SIDE</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.iside`]"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                                        @change="isideChanged"
                                                                        :disabled="isideDesabilitado"
																	/>
																</div>

                                                                <div class="form-group mr-4">
                                                                    <label>Conjunto Elétrico<ej-asterisk/></label><br>
                                                                    <ej-select
                                                                        v-model.trim="itemsBruteModel[`${index}.meta.opcionais.conjunto_eletrico`]"
                                                                        :options="['Analógico', 'Digital']"
                                                                        disabled
                                                                    ></ej-select>
                                                                </div>

                                                                <div class="form-group mr-4">
                                                                    <label>Paralama</label><br>
                                                                    <ej-toggle-button
                                                                        v-model.trim="itemsBruteModel[`${index}.meta.opcionais.paralama`]"
                                                                        :labels="{checked: 'Sim', unchecked: 'Não'}"
                                                                        :disabled="paralamaDesabilitado"
                                                                    />
                                                                </div>

																<div class="form-group mr-4">
																	<label>Limpador traseiro</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.limpador_traseiro`]"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                                        :disabled="limpadorTraseiroDesabilitado"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Giroflex</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.giroflex`]"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                                        :disabled="giroflexDesabilitado"
																	/>
																</div>

<!--																<div class="form-group mr-4">-->
<!--																	<label>Faróis de Milha</label><br>-->
<!--																	<ej-toggle-button-->
<!--																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.farois_milha`]"-->
<!--																		:labels="{checked: 'Sim', unchecked: 'Não'}"-->
<!--                                                                        :disabled="faroisMilha"-->
<!--																	/>-->
<!--																</div>-->

																<div class="form-group mr-4">
																	<label>Faróis Laterais</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.farois_laterais`]"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                                        :disabled="faroisLateraisDesabilitado"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Tomada 12V</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.tomada_12v`]"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                                        :disabled="tomadaDesabilitada"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Grade Florestal</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.grade_florestal`]"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                                        :disabled="gradeFlorestalDesabilitado"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Cortina</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.cortina`]"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                                        :disabled="cortinaDesabilitado"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Carregador de Celular USB</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.carregador_usb`]"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                                        :disabled="carregadorDesabilitado"
																	/>
																</div>

																<div
                                                                    class="form-group mr-4"
                                                                >
																	<label>Banco Lateral Articulado ST G3</label><br>
																	<ej-toggle-button
																		v-model="itemsBruteModel[`${index}.meta.opcionais.banco_lateral_articulado`]"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                                        :disabled="bancoLateralDesabilitado"
																	/>
																</div>

<!--																<div class="form-group mr-4">-->
<!--																	<label>Proteção Florestal de Policarbonato</label><br>-->
<!--																	<ej-toggle-button-->
<!--																		v-model.trim="itemsBruteModel[`${index}.meta.opcionais.protecao_florestal`]"-->
<!--																		:labels="{checked: 'Sim', unchecked: 'Não'}"-->
<!--																	/>-->
<!--																</div>-->
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td>{{ toCurrency(priceTotal) }}</td>
										<td>{{ toCurrency(discountTotal) }}</td>
										<td></td>
										<td>{{ toCurrency(grandTotal) }}</td>
										<td></td>
									</tr>
								</tfoot>
							</table>

							<div class="mobile-list-content">
								<div class="row">
									<div class="col-12 col-md-6" :key="index" v-for="(item, index) in itemsBrute">
										<div class="card">
											<div class="card-body">
												<h5 class="card-title">
													<strong>{{ `Item ${index+1}` }}</strong>
												</h5>

												<p class="card-text">
													<strong>Produto:</strong>
													<ej-select
														v-if="order.type === 'cabin'"
														:custom-label="productCustomLabel"
														:value="itemsBruteModel[index].product"
														@select="itemProductSelected(index, $event)"
														@remove="itemProductRemoved(index)"
														:options="compatibleProducts"
														placeholder="Buscar produto por nome"
														:loading="compatibleProductsFinding"
														track-by="id"
														label="name"
													></ej-select>
													<ej-select
														v-else
														:custom-label="productCustomLabel"
														v-model.trim="itemsBruteModel[index].product"
														:options="products"
														placeholder="Buscar produto por nome"
														:loading="productsFinding"
														track-by="id"
														label="name"
														@search-change="productAsyncFind"
													></ej-select>
												</p>

												<p class="card-text">
													<strong>Quantidade:</strong>
													<input type="number" class="form-control" v-model.trim="itemsBruteModel[index].quantity">
												</p>

												<p class="card-text">
													<strong>Preço Unit.:</strong>
													<currency-input v-model.trim="itemsBruteModel[index].price" type="text" class="form-control"/>
												</p>

												<p class="card-text">
													<strong>Desconto:</strong>
													<currency-input v-model.trim="itemsBruteModel[index].discount" type="text" class="form-control"/>
												</p>

												<p class="card-text">
													<strong>Op. Fiscal:</strong>
													<ej-select
														v-model="itemsBruteModel['['+index+'].meta.tax_operation']"
														:options="tax_operations"
														track-by="value"
														label="name"
                                                        :disabled="order.type === 'cabin'"
													></ej-select>
												</p>

												<p class="card-text">
													<strong>Estoque: </strong>
													<ej-button class="btn btn-primary">
														<font-awesome-icon :icon="['far', 'eye']"></font-awesome-icon>
													</ej-button>
												</p>

												<p class="card-text">
													<strong>Custo Unit.: </strong>
													<ej-button class="btn btn-primary">
														<font-awesome-icon :icon="['far', 'eye']"></font-awesome-icon>
													</ej-button>
												</p>

												<p class="card-text">
													<strong>Total:</strong>
													{{ toCurrency((itemsBruteModel[index].price * itemsBruteModel[index].quantity) - itemsBruteModel[index].discount) }}
												</p>

												<div v-if="_.get(itemsBruteModel[index],'product.type') === 'cabin'" class="row">
													<div class="form-group col-12">
														<label>Cor*</label>
														<ej-select
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.cor`]"
															:options="colors"
														></ej-select>
													</div>

													<div class="form-group col-12">
														<label>Esguichos*</label><br>
														<ej-select
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.esguichos`]"
															:options="['Dianteiro', 'Traseiro', 'Dianteiro e traseiro', 'Não']"
														></ej-select>
													</div>

													<div class="form-group col-12">
														<label>Sistema de Refrigeração*</label><br>
														<ej-select
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.refrigeracao`]"
															:options="['Ar Condicionado']"
                                                            :disabled="refrigeracaoDesabilitado"
														></ej-select>
													</div>

													<div class="form-group col-12">
														<label>Sistema de som*</label><br>
														<ej-select
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.sistema_som`]"
															:options="['Não', 'Sim, sem rádio', 'Sim, com rádio']"
                                                            :disabled="sistemaSomDesabilitado"
														></ej-select>
													</div>

													<div class="form-group col-12">
														<label>Filtro<ej-asterisk v-if="!filtroDesabilitado"></ej-asterisk></label><br>
														<ej-select
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.filtro`]"
															:options="['Anti-Pólen', 'Carvão Ativado']"
                                                            :disabled="filtroDesabilitado"
														></ej-select>
													</div>

													<div class="form-group col-6 text-center">
														<label>I-SIDE</label><br>
														<ej-toggle-button
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.iside`]"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                            :disabled="isideDesabilitado"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Limpador traseiro</label><br>
														<ej-toggle-button
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.limpador_traseiro`]"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Giroflex</label><br>
														<ej-toggle-button
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.giroflex`]"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

<!--													<div class="form-group col-6 text-center">-->
<!--														<label>Faróis de Milha</label><br>-->
<!--														<ej-toggle-button-->
<!--															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.farois_milha`]"-->
<!--															:labels="{checked: 'Sim', unchecked: 'Não'}"-->
<!--														/>-->
<!--													</div>-->

													<div class="form-group col-6 text-center">
														<label>Faroís Laterais</label><br>
														<ej-toggle-button
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.farois_laterais`]"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Tomada 12V</label><br>
														<ej-toggle-button
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.tomada_12v`]"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                            :disabled="tomadaDesabilitada"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Grade Florestal</label><br>
														<ej-toggle-button
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.grade_florestal`]"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Cortina</label><br>
														<ej-toggle-button
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.cortina`]"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Carregador de Celular USB</label><br>
														<ej-toggle-button
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.carregador_usb`]"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                            :disabled="carregadorDesabilitado"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Banco Lateral Articulado ST G3</label><br>
														<ej-toggle-button
															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.banco_lateral_articulado`]"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
                                                            :disabled="bancoLateralDesabilitado"
														/>
													</div>

<!--													<div class="form-group col-6 text-center">-->
<!--														<label>Proteção Florestal de Policarbonato</label><br>-->
<!--														<ej-toggle-button-->
<!--															v-model.trim="itemsBruteModel[`${index}.meta.opcionais.protecao_florestal`]"-->
<!--															:labels="{checked: 'Sim', unchecked: 'Não'}"-->
<!--														/>-->
<!--													</div>-->
												</div>

												<div class="actions">
													<ej-button class="btn btn-primary" @click="removeItem(index)" v-if="order.type !== 'cabin'">
														<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
													</ej-button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row d-flex justify-content-center" v-if="order.type !== 'cabin'">
						<ej-button class="btn btn-secondary hvr-pulse" @click="addItem">
							<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon> Item
						</ej-button>
					</div>

					<br>

					<div class="row">
						<div class="col">
							<h5>Pagamento</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-3 col-12">
							<label>Forma*</label>
							<ej-select
								v-model.trim="paymentSource"
								:options="paymentSources"
								track-by="value"
								label="name"
							></ej-select>
						</div>

						<div v-if="order.payment_source === 'financing'" class="form-group col-lg-3 col-12">
							<label>Banco</label>
							<input
								v-model.trim="orderMetaModel['financiamento.banco']"
								class="form-control"
								v-uppercase
							/>
						</div>

						<div class="form-group col-lg-2 col-12" v-if="_.get(order,'payment_source') === 'own_resources'">
							<label>Opção</label>
							<ej-select
								v-model.trim="paymentOption"
								:options="paymentOptions"
								track-by="value"
								label="name"
							></ej-select>
						</div>
					</div>

                    <div v-if="_.get(order,'payment_source') === 'own_resources' && _.get(order,'payment_option') === 'other'">
                        <div class="row">
                            <div class="form-group col-lg-4 col-12">
                                <label>Detalhamento*</label>
                                <textarea rows="5" class="form-control" v-model.trim="orderMetaModel['payment_description']"></textarea>
                            </div>
                        </div>
                    </div>

					<div v-if="_.get(order,'payment_source') === 'own_resources' && _.get(order,'payment_option') === 'times'">
						<div class="row">
							<div class="col">
								<h6>Distribuição</h6>
							</div>
						</div>

						<div class="row">
							<div class="col-12 col-md-3" :key="index" v-for="(installment, index) in order.installments">
								<div class="card">
									<div class="card-body">
										<h5 class="card-title">
											<strong>{{ index === 0 ? 'Entrada' : `Parcela ${index}`}}</strong>
										</h5>

										<p class="card-text">
											<strong>Prazo:</strong><br>

											<input type="radio" v-model.trim="order.installments[index].due_type" value="days"> Em dias, a partir da
                                            <select v-model="order.installments[index].from">
                                                <option value="delivery">Entrega</option>
                                                <option value="dispatch">Expedição</option>
                                                <option value="assembly">Montagem</option>
                                            </select>
											<span v-if="order.installments[index].due_type === 'date' && _.get(order.installments[index], 'due_days')">
												({{ order.installments[index].due_days }})
											</span>
											<br>

											<input type="radio" v-model.trim="order.installments[index].due_type" value="date"> Em data
											<span v-if="order.installments[index].due_type === 'days' && _.get(order.installments[index], 'due_date')">
												({{ moment(order.installments[index].due_date).locale('pt-br').format('DD/MM/YYYY') }})
											</span>
											<br>

											<input
												v-if="order.installments[index].due_type === 'days'"
												type="number"
												v-model.number="order.installments[index].due_days"
												@change="dueDayChanged($event.target.value, index)"
												class="form-control"
												placeholder="Insira a qtd. de dias aqui"
											>
											<ej-date-picker
												v-if="order.installments[index].due_type === 'date'"
												v-model.trim="order.installments[index].due_date"
												@change="dueDateChanged($event, index)"
												placeholder="Insira a data aqui"
											></ej-date-picker>
										</p>

										<p class="card-text">
											<strong>Valor:</strong>
											<currency-input
												v-model.trim="order.installments[index].value"
												class="form-control"
												placeholder="Insira o valor aqui"
											></currency-input>
										</p>

										<div class="actions">
											<button class="btn btn-primary" @click="removeInstallment(index)">
												<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
											</button>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-md-3 d-flex align-items-center justify-content-center">
								<ej-button class="btn btn-secondary hvr-pulse" @click="addInstallment">
									<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon> Parcela
								</ej-button>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<h5>Comissões</h5>
						</div>
					</div>

					<div class="row">
                        <div class="form-group col-lg-3 col-12">
                            <label>Emitente*</label>
                            <ej-select
                                v-model.trim="seller"
                                :options="sellers"
                                track-by="id"
                                label="name"
                                :disabled="true"
                            ></ej-select>
                        </div>

                        <div class="form-group col-lg-auto col-6">
                            <label>Tipo</label><br>
                            <ej-select
                                v-model.trim="sellerValueType"
                                track-by="value"
                                label="name"
                                :options="valueTypes"
                            ></ej-select>
                        </div>

                        <div class="form-group col-lg-auto col-6">
                            <label>Valor</label>
                            <currency-input
                                v-model.trim="orderMetaModel['comissoes.emitente.value']"
                                :currency="orderMetaModel['comissoes.emitente.value_type'] === 'fixed_value' ? 'BRL' : {suffix: ' %'}"
                                type="text"
                                class="form-control"
                            />
                        </div>

                        <div
                            v-if="$store.state.auth.permissions['release_orders_with_status_new_or_rejected_for_anyone'] && order.status !== 'budget'" class="form-group col-lg-auto col-6"
                        >
                            <label>Data do Pagamento</label>
                            <ej-date-picker
                                v-model.trim="orderMetaModel['comissoes.emitente.payment_date']"
                            ></ej-date-picker>
                        </div>
                    </div>

                    <template v-if="reseller">
                        <hr>

                        <div class="row">
                            <div class="form-group col-lg-3 col-12">
                                <label>Revenda*</label>
                                <ej-select
                                    v-model.trim="reseller"
                                    :options="resellers"
                                    track-by="id"
                                    :custom-label="resellerCustomLabel"
                                    :disabled="true"
                                ></ej-select>
                            </div>

                            <div class="form-group col-lg-auto col-6">
                                <label>Tipo</label><br>
                                <ej-select
                                    v-model.trim="resellerValueType"
                                    :options="valueTypes"
                                    track-by="value"
                                    label="name"
                                ></ej-select>
                            </div>

                            <div class="form-group col-lg-auto col-6">
                                <label>Valor</label>
                                <currency-input
                                    v-model.trim="orderMetaModel['comissoes.revenda.value']"
                                    :currency="orderMetaModel['comissoes.revenda.value_type'] === 'fixed_value' ? 'BRL' : {suffix: ' %'}"
                                    type="text"
                                    class="form-control"
                                />
                            </div>

                            <div v-if="order.status !== 'budget'" class="form-group col-lg-auto col-6">
                                <label>Data do Pagamento</label>
                                <ej-date-picker
                                    v-model.trim="orderMetaModel['comissoes.revenda.payment_date']"
                                ></ej-date-picker>
                            </div>
                        </div>
                    </template>

                    <hr>

                    <div class="row">
                        <div class="form-group col-lg-3 col-12">
                            <label>Promotor</label>
                            <ej-select
                                v-model.trim="indicator"
                                :options="indicators"
                                track-by="id"
                                :custom-label="indicatorCustomLabel"
                                @search-change="indicatorAsyncFind"
                                :loading="indicatorFinding"
                            ></ej-select>
                        </div>

                        <div class="form-group col-lg-auto col-6">
                            <label>Tipo</label><br>
                            <ej-select
                                v-model.trim="indicatorValueType"
                                track-by="value"
                                label="name"
                                :options="valueTypes"
                            ></ej-select>
                        </div>

                        <div class="form-group col-lg-auto col-6">
                            <label>Valor</label>
                            <currency-input
                                v-model.trim="orderMetaModel['comissoes.promotor.value']"
                                :currency="orderMetaModel['comissoes.promotor.value_type'] === 'fixed_value' ? 'BRL' : {suffix: ' %'}"
                                type="text"
                                class="form-control"
                            />
                        </div>

                        <div v-if="order.status !== 'budget'" class="form-group col-lg-auto col-6">
                            <label>Data do Pagamento</label>
                            <ej-date-picker
                                v-model.trim="orderMetaModel['comissoes.promotor.payment_date']"
                            ></ej-date-picker>
                        </div>
                    </div>

					<br>

					<div class="row">
						<div class="col">
							<h5>Entrega</h5>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<h5>Frete</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-2 col-12">
							<label>Tipo de frete*</label>
							<ej-select
								track-by="value"
								label="name"
								v-model="tipoFrete"
								:options="shipping_types"
							></ej-select>
						</div>

						<div class="form-group col-lg-3 col-12">
							<label>Transportadora</label>
							<ej-select
								v-model.trim="orderMetaModel['frete.transportadora']"
								:options="carriers"
								track-by="CODCLIFORN"
								label="DESCRCLIFORN"
								@search-change="carrierAsyncFind"
								:loading="carrierFinding"
							></ej-select>
						</div>

<!--                        <div class="form-group col-lg-2 col-12">-->
<!--                            <label>Valor</label>-->
<!--                            <currency-input-->
<!--                                v-model.trim="orderMetaModel['frete.valor']"-->
<!--                                :currency="'BRL'"-->
<!--                                type="text"-->
<!--                                class="form-control"-->
<!--                            />-->
<!--                        </div>-->
					</div>

					<div class="row">
						<div class="col">
							<h5>Montagem</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-4 col-12">
							<div>
								<label style="margin-bottom: 0">Onde?</label><br>
								<small style="line-height: 16px;display: block;margin-bottom: 5px;">(deixe esse campo vazio se a montagem for no mesmo endereço do cliente)</small>
							</div>
							<textarea v-model.trim="orderMetaModel['montagem']" rows="5" class="form-control"></textarea>
						</div>
					</div>

					<br>

					<div class="row">
						<div class="col">
							<h5>Observações</h5>
						</div>
					</div>

                    <div class="row">
                        <div class="form-group col-12">
                            <div id="memorial">
                                <span style="font-weight: 900">MEMORIAL DE CÁLCULO IMPLEMASTER:</span><br>
                                <strong>- PEDIDO:</strong> {{ order.id }}<br>
                                <div>
                                    <strong>- MARCA:</strong> <content-editable v-model="orderMetaModel['memorial.marca']"></content-editable>
                                </div>
                                <div>
                                    <strong>- MODELO:</strong> <content-editable v-model="orderMetaModel['memorial.modelo']"></content-editable>
                                </div>
                                <div>
                                    <strong>- OPCIONAIS:</strong> <content-editable v-model="orderMetaModel['memorial.opcionais']"></content-editable>
                                </div>
                                <div>
                                    <strong>- CUSTO OPCIONAIS:</strong> <content-editable :money="true" v-model="orderMetaModel['memorial.custo_opcionais']"></content-editable>
                                </div>
                                <div>
                                    <strong>- ICMS:</strong> <content-editable :money="true" v-model="orderMetaModel['memorial.icms']"></content-editable>
                                </div>
                                <div>
                                    <strong>- CUSTO FOB - CABINA FÁBRICA:</strong> <content-editable :money="true" v-model="orderMetaModel['memorial.custo_fob']"></content-editable>
                                </div>
                                <div>
                                    <strong>- FRETE:</strong> <content-editable :money="true" v-model="orderMetaModel['memorial.frete']"></content-editable>
                                </div>
                                <div>
                                    <strong>- MONTAGEM:</strong> <content-editable :money="true" v-model="orderMetaModel['memorial.montagem']"></content-editable>
                                </div>
                                <div>
                                    <content-editable v-model="orderMetaModel['memorial.outro']"></content-editable>
                                </div>
                                <div>
                                    <strong>- CUSTO TOTAL:</strong> <content-editable :money="true" :contenteditable="true" v-model="orderMetaModel['memorial.custo_total']"></content-editable>
                                </div>
                                <div>
                                    <strong>- VALOR DA VENDA:</strong> <content-editable :money="true" :contenteditable="false" v-model="orderMetaModel['memorial.valor_venda']"></content-editable>
                                </div>
                                <div>
                                    <strong>- CRÉDITO COMISSÃO:</strong> <content-editable :money="true" :contenteditable="false" v-model="orderMetaModel['memorial.credito_comissao']"></content-editable>
                                </div>
                                <br>
                                <span style="font-weight: 900">INFORMAÇÕES INTERNAS PARA CC COMISSÃO:</span><br>
                                <div>
                                    <strong>- COMISSÃO REVENDA:</strong> <content-editable :money="true" :contenteditable="true" v-model="orderMetaModel['memorial.comissao_revenda']"></content-editable>
                                </div>
                                <br>
                                <span style="font-weight: 900">INFORMAÇÕES INTERNAS PARA CC IMPLEMASTER:</span><br>
                                <div>
                                    <strong>- FORMA DE PAGAMENTO:</strong> <content-editable v-model="orderMetaModel['memorial.forma_pagamento']"></content-editable>
                                </div>
                            </div>
                        </div>
                    </div>

					<div class="row">
						<div class="form-group col-lg-4 col-12">
							<label>Observações (Esta observação é de visualização <span style="font-weight: 900">PÚBLICA</span>!)</label>
							<textarea rows="10" class="form-control" v-model.trim="order.ps"></textarea>
						</div>

						<div class="form-group col-lg-4 col-12">
							<label style="color: red">Observações internas (Esta observação é de visualização <span style="font-weight: 900">INTERNA</span>!)</label>
							<textarea rows="10" class="form-control" v-model.trim="order.ps_internal" placeholder="NÃO LANCE O MEMORIAL DE CÁLCULO AQUI!"></textarea>
						</div>
					</div>

					<br>

					<div class="row">
						<div class="col">
							<h5>Outros</h5>
						</div>
					</div>

					<div class="row">
						<div v-if="order.status === 'budget'" class="form-group col-lg-2 col-12">
							<label>Validade do orçamento* <font-awesome-icon v-tooltip="'Em dias'" :icon="['far', 'question-circle']"></font-awesome-icon></label>
							<input
								type="number"
								v-model.trim="order.validity"
								placeholder="Em dias"
								class="form-control"
							/>
						</div>

						<div v-else class="form-group col-lg-3 col-12">
							<label>Previsão de Entrega <font-awesome-icon v-tooltip="'Texto livre, pode ser informar o que você quiser'" :icon="['far', 'question-circle']"></font-awesome-icon></label>
							<input
								type="text"
								v-model.trim="order.delivery_forecast"
								class="form-control"
							/>
						</div>
					</div>

					<br>

					<div class="row">
						<div class="col">
							<h5>Confirmação*</h5>
						</div>
					</div>

					<div class="row">
						<form class="form-group col">
							<label>Estou ciente que qualquer informação preenchida incorretamente neste pedido/orçamento, poderá me trazer custos extras.</label><br>
							<ej-toggle-button
								v-model.trim="confirmation"
								:labels="{checked: 'Sim', unchecked: 'Não'}"
							/>
						</form>
					</div>

					<br>

					<div class="row d-flex justify-content-center">
						<ej-button
							@click="salvar"
							class="btn btn-secondary hvr-pulse"
							:disabled="saving"
						>
							<strong>Salvar  <font-awesome-icon :icon="['fas', 'cog']" spin v-if="saving"></font-awesome-icon></strong>
						</ej-button>
					</div>
				</div>
			</main>
		</ej-content>
	</ej-page>
</template>

<script>
	//Mixins
	import FetchingMixin from "@/mixins/FetchingMixin";
	import Swal from "sweetalert2";
	import {exibeErro, sanitize} from "@/helpers";
	import CurrencyMixin from "@/mixins/CurrencyMixin";

	//Aux
	const _ = require('lodash');
	import moment from 'moment';
	import EjButton from "@/components/EjButton";
	import EjBackButton from "@/components/EjBackButton";
	import EjToggleButton from "@/components/EjToggleButton";
	import {vueSet} from "vue-deepset";
	import Push from "push.js";

    //Componentes
    import PhotoSwipe from "@/components/PhotoSwipe";
    import EjDropzone from "@/components/EjDropzone";
    import ContentEditable from "@/components/ContentEditable";
    import {unformat} from "v-money/utils";

    export default {
		name: 'OrderEdit',

		components: {
            ContentEditable,
			EjToggleButton,
			EjBackButton,
			EjButton,
            PhotoSwipe,
            EjDropzone,
		},

		data() {
			return {
				familia: undefined,
				operation: undefined,
				confirmation: false,
				loadingOrder: false,
				loadingIndicators: false,
				loadingCategories: false,
				loadingContacts: false,
				saving: false,
				commission: false,
                bancoLateralDesabilitado: false,
                refrigeracaoDesabilitado: false,
                sistemaSomDesabilitado: false,
                carregadorDesabilitado: false,
                isideDesabilitado: false,
                giroflexDesabilitado: false,
                paralamaDesabilitado: false,
                tanqueInferiorDesabilitado: false,
                tanqueSuperiorDesabilitado: false,
                tanqueTraseiroDesabilitado: false,
                esguichoDianteiroDesabilitado: false,
                esguichoTraseiroDesabilitado: false,
                esguichoDianteiroTraseiroDesabilitado: false,
                tomadaDesabilitada: false,
                filtroDesabilitado: false,
                limpadorTraseiroDesabilitado: false,
                faroisLateraisDesabilitado: false,
                gradeFlorestalDesabilitado: false,
                cortinaDesabilitado: false,

				order: {
                    id: undefined, //fixme remover
					date: undefined,
					type: undefined,
					po: undefined,
					price_list: undefined,
                    activity: undefined,
					status: 'new',
					customer_id: undefined,
					customer: {
						name: undefined
					},
                    photos: [],
					validity: undefined,
                    indicator: {
                        name: undefined
                    },
                    indicator_id: undefined,
                    contact: {
						name: undefined
					},
					payment_source: undefined,
					payment_option: undefined,
                    reseller_id: undefined,
					seller_id: undefined,
					contact_id: undefined,
					reseller: {
						name: undefined
					},
					installments: undefined,
					items: [],
					ps: undefined,
					ps_internal: undefined,
					delivery_forecast: undefined,
				},

				/* Criei o meta fora do order por causa do problema de reatividade do Vue,
				no final eu incluo ele pra salvar */
				orderMeta: {
                    frete:{
                        tipo: 0
                    },
                    comissoes:{
                        promotor: {
                            value_type: 'fixed_value'
                        },
                        revenda: {
                            value_type: 'fixed_value'
                        },
                        emitente: {
                            value_type: 'fixed_value'
                        },
                    },
                    memorial: {
                        marca: "",
                        modelo: "",
                        opcionais: "",
                        custo_opcionais: "",
                        icms: "",
                        custo_fob: "",
                        frete: "",
                        montagem: "",
                        outro: "",
                        custo_total: "",
                        valor_venda: "",
                        credito_comissao: "",
                        comissao_revenda: "",
                        forma_pagamento: "",
                    }
                },

				paymentSources:[
					{name:'Recursos próprios',value:'own_resources'},
					{name:'Financiamento',value:'financing'}
				],
				paymentOptions: [
					{name:'A vista',value:'cash'},
					{name:'A prazo',value:'times'},
					{name:'Outro',value:'other'},
				],
				price_lists: [
					{name:'Consumidor final',value:'3'},
					{name:'Revendas',value:'4'}
				],
				activities: [
					{name:'Garantia',value:'3'},
					{name:'Outras saídas',value:'4'},
					{name:'Venda de Cabinas',value:'1'},
					{name:'Venda de Peças',value:'2'},
				],
				categories: [],
				compatibleProducts: [],
				products: [],
				tax_operations: [
					{name:'Venda normal',value:'1'},
					{name:'Frete',value:'10'},
				],
				types: [
					{name:'Cabina',value:'cabin'},
					{name:'Outro',value:'other'},
				],
				itemsBrute: [],
				colors: [
					'AMARELO',
					'AZUL',
					'AZUL FORD',
					'AZUL NH',
					'BRANCO',
					'DOURADO',
					'LARANJA',
					'PRATA',
					'VERDE',
					'VERDE KAWASAKI',
					'VERMELHO',
				],
				shipping_types: [
					{name: "Pago (CIF)", value: 0},
					{name: "A pagar (FOB)", value: 2},
					{name: "Sem frete", value: 9}
				],
				motors: [],
                valueTypes: [
                    {
                        value: 'fixed_value',
                        name: 'Valor Fixo',
                    },
                    {
                        value: 'percentage',
                        name: 'Percentual',
                    },
                ],
				tankOptions: [
					'Não',
					'Sim, de ferro',
					'Sim, de plástico'
				],
				compatibleProductsFinding: false,
				productsFinding: false,

				customers: [],
				customerFinding: false,

				sellers: [],
				sellerFinding: false,

				resellers: [],
				resellerFinding: false,

				indicators: [],
				indicatorFinding: false,

				contacts: [],
				contactFinding: false,

				equipments: [],
				equipmentFinding: false,

				carriers: [],
				carrierFinding: false,
			}
		},

		watch: {
            familia(newValue) {
                switch (newValue) {
                    case 1:
                        this.itemsBruteModel['0.meta.opcionais.conjunto_eletrico'] = this.itemsBruteModel['0.meta.opcionais.iside']
                            ? 'Digital'
                            : 'Analógico';
                        break;

                    case 2:
                        this.itemsBruteModel['0.meta.opcionais.conjunto_eletrico'] = 'Digital';
                        break;

                    default:
                        this.itemsBruteModel['0.meta.opcionais.conjunto_eletrico'] = 'Analógico';
                }
            },

            itemsBruteModel: {
                handler: function () {
                    const vm = this;

                    let opcionais = [
                        {
                            field: "0.meta.opcionais.banco_lateral_articulado",
                            name: "BANCO LATERAL ARTICULADO ST G3"
                        },
                        {
                            field: "0.meta.opcionais.carregador_usb",
                            name: "CARREGADOR DE CELULAR USB"
                        },
                        {
                            field: "0.meta.opcionais.cor",
                            name: `COR`,
                            concat_value: true
                        },
                        {
                            field: "0.meta.opcionais.cortina",
                            name: "CORTINA"
                        },
                        {
                            field: "0.meta.opcionais.esguichos",
                            name: `ESGUICHO`,
                            concat_value: true
                        },
                        {
                            field: "0.meta.opcionais.farois_milha",
                            name: "FARÓIS MILHA"
                        },
                        {
                            field: "0.meta.opcionais.farois_laterais",
                            name: "FARÓIS LATERAIS"
                        },
                        {
                            field: "0.meta.opcionais.giroflex",
                            name: "GIROFLEX"
                        },
                        {
                            field: "0.meta.opcionais.grade_florestal",
                            name: "GRADE FLORESTAL"
                        },
                        {
                            field: "0.meta.opcionais.iside",
                            name: "I-SIDE"
                        },
                        {
                            field: "0.meta.opcionais.limpador_traseiro",
                            name: "LIMPADOR TRASEIRO"
                        },
                        {
                            field: "0.meta.opcionais.paralama",
                            name: "PARALAMA"
                        },
                        {
                            field: "0.meta.opcionais.protecao_florestal",
                            name: "PROTEÇÃO FLORESTAL DE POLICARBONATO"
                        },
                        {
                            field: "0.meta.opcionais.sistema_som",
                            name: `SISTEMA DE SOM`,
                            concat_value: true
                        },
                        {
                            field: "0.meta.opcionais.tomada_12v",
                            name: "TOMADA 12V"
                        },
                    ];

                    this.orderMetaModel['memorial.opcionais'] = opcionais
                        .reduce(function (ac, o) {
                            if (vm.itemsBruteModel[o.field] && o.field !== '0.meta.opcionais.cor')
                                ac.push(o.name + (o.concat_value ? ' ' + vm.itemsBruteModel[o.field].toUpperCase() : ''));

                            return ac;
                        }, [])
                        .join(", ");
                },
                deep: true
            },

            category(newValue) {
                if (newValue) {
                    const vm = this;
                    this.equipmentFinding = true;

                    this.$store.dispatch(`products/index`, {
                        // "filter[products.name]": keyword,
                        "filter[products.type]": 'equipment',
                        "filter[categories]": [newValue.id],
                        "filter[active]": true,
                        "sort": "products.name",
                        "per_page": 9999
                    })
                        .then(function (response) {
                            vm.equipments = response.data.data.map((c) => {
                                return _.pick(c, [
                                    'id',
                                    'brand',
                                    'name',
                                    'code',
                                ])
                            });
                        })
                        .catch(function (error) {
                            // handle error
                            exibeErro(error);
                        })
                        .then(function () {
                            // always executed
                            vm.equipmentFinding = false;
                        });
                }
            },

            "grandTotal"(newValue) {
                this.orderMetaModel['memorial.valor_venda'] = this.toCurrency(newValue);
            },

            "orderMeta.comissoes.revenda.value"(newValue) {
                this.orderMetaModel['memorial.comissao_revenda'] = this.toCurrency(
                    this.orderMetaModel['comissoes.revenda.value_type'] === 'fixed_value'
                        ? newValue
                        : this.grandTotal * newValue / 100
                );
            },

            "orderMeta.memorial.custo_total"() {
                let custo_total = this.orderMetaModel['memorial.custo_total'] ? parseFloat(unformat(this.orderMetaModel['memorial.custo_total'],2)) : 0;
                let valor_venda = this.orderMetaModel['memorial.valor_venda'] ? parseFloat(unformat(this.orderMetaModel['memorial.valor_venda'],2)) : 0;

                this.orderMetaModel['memorial.credito_comissao'] = this.toCurrency(valor_venda - custo_total);
            },

            "orderMeta.memorial.frete"() {
                this.refazSoma();
            },

            "orderMeta.memorial.custo_opcionais"() {
                this.refazSoma();
            },

            "orderMeta.memorial.icms"() {
                this.refazSoma();
            },

            "orderMeta.memorial.custo_fob"() {
                this.refazSoma();
            },

            "orderMeta.memorial.montagem"() {
                this.refazSoma();
            },

            "orderMeta.equipamento.serie"(newValue) {
                this.orderMetaModel['memorial.marca'] = _.get(newValue,'brand');
                this.orderMetaModel['memorial.modelo'] = _.get(newValue,'name');

                //Se for Colheitadeira e da marca "NEW HOLLAND"
                if(this.category.id === 2 && _.get(newValue,'brand') === "NEW HOLLAND") {
                    this.motors = [
                        'CUMMINS',
                        'FORD',
                        'MERCEDES',
                        'GENESIS'
                    ];

                //trator
                } else if (this.category.id === 1 && _.get(newValue,'brand') === "NEW HOLLAND") {
                    this.motors = [
                        'IVECO',
                        'MWM',
                    ];

                //trator
                } else if (this.category.id === 1 && _.get(newValue,'brand') === "VALTRA") {
                    this.motors = [
                        'MWM',
                        'VALTRA'
                    ];

                //trator
                } else if (this.category.id === 1 && _.get(newValue,'brand') === "JOHN DEERE") {
                    this.motors = [
                        'DIESEL 3029H – 3 Cilindros',
                        'DIESEL 4045H – 4 Cilindros'
                    ];

                } else {
                    this.motors = [];
                }
            },

            "orderMeta.equipamento.categoria": {
                handler: function(newValue) {
                    /* Evita que o código seja executado no arranque, quando o valor de this.order é definido,
                    se não tiver esse if aqui dá merda */
                    if (!this.loadingOrder && this.order.type === 'cabin') {

                        //Se for colheitadeira não tem filtro
                        if (newValue.id == 2)
                            this.ocultaFiltro();
                        else
                            this.exibeFiltro();
                    }
                }
            },

			"orderMeta.equipamento.cor": {
				handler: function(newValue) {
					/* Evita que o código seja executado no arranque, quando o valor de this.order é definido,
					se não tiver esse if aqui dá merda */
					if (!this.loadingOrder && this.order.type === 'cabin') {
						this.itemsBruteModel['0.meta.opcionais.cor'] = newValue;
					}
				},
                immediate: true
			},

			"order.payment_source"(newValue) {
				switch (newValue) {
					case 'financing':
						this.order.payment_option = undefined;
						this.order.installments = [];

                        this.orderMetaModel['memorial.forma_pagamento'] = 'Financiamento';
						break;

					case 'own_resources':
						this.$delete(this.orderMeta, 'financiamento');
						break;
				}

				if (!this.loadingOrder)
					this.zzz();
			},

			"order.payment_option": {
				handler: function(newValue) {
					switch (newValue) {
						case 'cash':
							this.order.installments = [];

                            this.orderMetaModel['memorial.forma_pagamento'] = 'A vista';
							break;

                        case 'times':
                            this.orderMetaModel['memorial.forma_pagamento'] = 'A prazo';
                            break;
					}
				}
			},

			"order.type": {
				handler: function(newValue) {
					/* Evita que o código seja executado no arranque, quando o valor de this.order é definido,
					se não tiver esse if aqui dá merda */
					if (!this.loadingOrder) {
						vueSet(this, 'itemsBrute', []);

						if (newValue === 'other') {
                            this.order.activity = 2;
                            this.$delete(this.orderMeta, 'equipamento');

                        } else if (newValue === 'cabin') {
                            this.order.activity = 1;
                        }

						if (newValue)
							//Já cria um item automaticamente para facilitar a vida do usuário
							this.itemsBrute.push({
								product: undefined,
								meta: {},
								quantity: 1,
								discount: 0,
								price: undefined,
							});
					}
				}
			},

			"order.status"() {
				if (!this.loadingOrder)
					this.zzz();
			}
		},

		computed: {
            esguichosOptions() {
                return [
                    {name: 'Dianteiro', $isDisabled: this.esguichoDianteiroDesabilitado},
                    {name: 'Traseiro', $isDisabled: this.esguichoTraseiroDesabilitado},
                    {name: 'Dianteiro e traseiro', $isDisabled: this.esguichoDianteiroTraseiroDesabilitado}
                ]
            },

            canUpdateSeller() {
				return this.$store.state.auth.permissions['update_orders_in_any_case']
					|| this.$store.state.auth.permissions['update_order_data_except_status_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_budget_to_new_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_rejected_to_new_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_released_to_launched_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_shipped_to_delivered_for_anyone'];
			},

			tipoFrete: {
				get() {
					return this.shipping_types.find((t) => t.value == _.get(this.orderMeta, 'frete.tipo'));
				},
				set(tipo) {
                    vueSet(this.orderMeta, 'frete.tipo', tipo ? tipo.value : null);
				}
			},

			orderName() {
				return this.order.status === 'budget' ? 'Orçamento' : 'Pedido';
			},

			orderMetaModel() {
				return this.$deepModel(this.orderMeta);
			},

			itemsBruteModel() {
				return this.$deepModel(this.itemsBrute);
			},

			statuses() {
                return [
                    {'name': 'CANCELADO', 'value': 'canceled', $isDisabled: true},
                    {'name': 'ENTREGUE', 'value': 'delivered', $isDisabled: true},
                    {'name': 'LANÇADO', 'value': 'launched', $isDisabled: true},
                    {'name': 'LIBERADO', 'value': 'released', $isDisabled: true},
                    {'name': 'NOVO', 'value': 'new'},
                    {'name': 'ORÇAMENTO', 'value': 'budget'},
                    {'name': 'PRODUZIDO', 'value': 'produced', $isDisabled: true},
                    {'name': 'REJEITADO', 'value': 'rejected', $isDisabled: true},
                ];
			},

			grandTotal() {
				return this.itemsBrute.reduce((ac, item) => ac += (item.quantity * item.price) - item.discount,0);
			},

			priceTotal() {
				return this.itemsBrute.reduce((ac, item) => ac += (item.quantity * item.price),0);
			},

			discountTotal() {
				return this.itemsBrute.reduce((ac, item) => ac += item.discount,0);
			},

			customer: {
				get() {
					const customer_id = _.get(this.order, 'customer.id');
					return customer_id ? this.customers.find((c) => c.id == customer_id) : null;
				},

				set(customer) {
					this.order.customer = customer;
					this.order.customer_id = customer ? customer.id : null;
				}
			},

			equipment: {
				get() {
					return _.get(this.orderMeta, 'equipamento.serie');
				},

				async set(equipment) {
                    console.debug('equipment:set', equipment);

                    this.orderMetaModel['equipamento.serie'] = equipment;

					if (equipment) {
                        await this.compatibleProductAsyncFind(equipment);
                    }
				}
			},

			// color: {
			// 	get() {
			// 		if (this.order.type === 'cabin')
			// 			return _.get(this.orderMetaModel,'equipamento.cor');
			// 		else
			// 			return null;
			// 	},
            //
			// 	set(color) {
			// 		this.orderMetaModel['equipamento.cor'] = color;
			// 	}
			// },

			status: {
				get() {
					const status = _.get(this.order,'status');
					return this.statuses.find((s) => s.value === status);
				},

				set(status) {
					this.order.status = status ? status.value : null;
				}
			},

			activity: {
				get() {
					const activity = _.get(this.order,'activity');
					return this.activities.find((s) => s.value == activity);
				},

				set(activity) {
					this.order.activity = activity ? activity.value : null;
				}
			},

			price_list: {
				get() {
					const price_list = _.get(this.order,'price_list');
					return this.price_lists.find((s) => s.value == price_list);
				},

				set(price_list) {
					this.order.price_list = price_list ? price_list.value : null;
				}
			},

			motor: {
				get() {
					if (this.order.type === 'cabin')
						return _.get(this.orderMeta.equipamento, 'motor');
					else
						return null;
				},

				set(motor) {
					vueSet(this.orderMeta, 'equipamento.motor', motor);
				}
			},

			contact: {
				get() {
					const contact_id = _.get(this.order, 'contact_id');
					return contact_id ? this.contacts.find((c) => c.id == contact_id) : null;
				},

				set(contact) {
					this.order.contact_id = contact.id;
				}
			},

            indicator: {
                get() {
                    const indicator_id = _.get(this.order, 'indicator_id');
                    return indicator_id ? this.indicators.find((c) => c.id == indicator_id) : null;
                },

                set(indicator) {
                    this.order.indicator_id = indicator ? indicator.id : null;
                }
            },

            indicatorValueType: {
                get() {
                    const reg = this.orderMetaModel['comissoes.promotor.value_type'];
                    return reg ? this.valueTypes.find((c) => c.value == reg) : null;
                },

                set(type) {
                    vueSet(this.orderMeta, 'comissoes.promotor.value_type', (type) ? type.value : null);
                }
            },

            resellerValueType: {
                get() {
                    const reg = this.orderMetaModel['comissoes.revenda.value_type'];
                    return reg ? this.valueTypes.find((c) => c.value == reg) : null;
                },

                set(type) {
                    vueSet(this.orderMeta, 'comissoes.revenda.value_type', (type) ? type.value : null);
                }
            },

            sellerValueType: {
                get() {
                    const reg = this.orderMetaModel['comissoes.emitente.value_type'];
                    return reg ? this.valueTypes.find((c) => c.value == reg) : null;
                },

                set(type) {
                    vueSet(this.orderMeta, 'comissoes.emitente.value_type', (type) ? type.value : null);
                }
            },

            seller: {
                get() {
                    const seller_id = _.get(this.order, 'seller_id');
                    return seller_id ? this.sellers.find((c) => c.id == seller_id) : null;
                },

                set(seller) {
                    this.order.seller_id = seller.id;
                }
            },


            reseller: {
				get() {
					const reseller_id = _.get(this.order, 'reseller.id');
					return reseller_id ? this.resellers.find((c) => c.id == reseller_id) : null;
				},

				set(reseller) {
					this.order.reseller = reseller;
					this.order.reseller_id = reseller ? reseller.id : null;
				}
			},

			paymentSource: {
				get() {
					const paymentSource = _.get(this.order, 'payment_source');
					return paymentSource ? this.paymentSources.find((c) => c.value == paymentSource) : null;
				},

				set(payment_source) {
					this.order.payment_source = (payment_source) ? payment_source.value : null;
				}
			},

			paymentOption: {
				get() {
					const paymentOption = _.get(this.order, 'payment_option');
					return paymentOption ? this.paymentOptions.find((c) => c.value == paymentOption) : null;
				},

				set(payment_option) {
					this.order.payment_option = (payment_option) ? payment_option.value : null;
				}
			},

			type: {
				get() {
					const type = _.get(this.order, 'type');
					return type ? this.types.find((t) => t.value == type) : null;
				},

				set(type) {
					this.order.type = (type) ? type.value : null;
				}
			},

			category: {
				get() {
					if (this.order.type === 'cabin')
						return _.get(this.orderMeta.equipamento, 'categoria');
					else
						return null;
				},

				set(type) {
					this.equipment = null;
					this.equipments = [];

					vueSet(this.orderMeta, 'equipamento.categoria', type);
				}
			},

            photosModel() {
                return this.$deepModel(this.order.photos);
            },

			spinner() {
				return this.loadingOrder || this.loadingCategories || this.loadingSellers;
			}
		},

		mixins: [
			FetchingMixin,
			CurrencyMixin
		],

		methods: {
            equipmentSelected(value) {
                console.debug('equipmentSelected', value);

                this.equipment = value;
            },

            isideChanged() {
                console.debug('isideChanged');

                //If checked, so digital
                if (this.itemsBruteModel['0.meta.opcionais.iside']) {
                    this.exibeGiroflex();
                    this.exibeFiltro();
                    this.exibeCortina();
                    this.exibeFaroisLaterais();
                    this.exibeTomada12V();
                    this.exibeGradeFlorestal();
                    this.exibeLimpadorTraseiro();
                    this.exibeEsguichoDianteiro();
                    this.exibeEsguichoDianteiroTraseiro();
                    this.exibeEsguichoTraseiro();
                    this.ocultaSistemaRefrigeracao();
                    this.ocultaSOM();
                    this.ocultaCarregadorUSB();

                //Else, so analogic
                } else {
                    this.exibeGiroflex();
                    this.exibeFiltro();
                    this.exibeCortina();
                    this.exibeLimpadorTraseiro();
                    this.exibeFaroisLaterais();
                    this.exibeSOM();
                    this.exibeTomada12V();
                    this.exibeGradeFlorestal();
                    this.exibeCarregadorUSB();
                    this.exibeEsguichoDianteiro();
                    this.exibeEsguichoTraseiro();
                    this.exibeEsguichoDianteiroTraseiro();
                    this.ocultaSistemaRefrigeracao();
                }

                this.ocultaParalama();
            },

            refazSoma() {
                let custo_opcionais = this.orderMetaModel['memorial.custo_opcionais'] ? parseFloat(unformat(this.orderMetaModel['memorial.custo_opcionais'],2)) : 0;
                let icms = this.orderMetaModel['memorial.icms'] ? parseFloat(unformat(this.orderMetaModel['memorial.icms'],2)) : 0;
                let custo_fob = this.orderMetaModel['memorial.custo_fob'] ? parseFloat(unformat(this.orderMetaModel['memorial.custo_fob'],2)) : 0;
                let montagem = this.orderMetaModel['memorial.montagem'] ? parseFloat(unformat(this.orderMetaModel['memorial.montagem'],2)) : 0;
                let frete = this.orderMetaModel['memorial.frete'] ? parseFloat(unformat(this.orderMetaModel['memorial.frete'],2)) : 0;

                this.orderMetaModel['memorial.custo_total'] = this.toCurrency(custo_opcionais + icms + custo_fob + montagem + frete);
            },

            exibeISIDE() {
                console.debug('exibeISIDE');
                this.isideDesabilitado = false;
                //this.itemsBruteModel['0.meta.opcionais.iside'] = false;
                this.isideChanged();
            },

            ocultaISIDE() {
                console.debug('ocultaISIDE');
                this.isideDesabilitado = true;
                this.itemsBruteModel['0.meta.opcionais.iside'] = false;
                this.isideChanged();
            },

            exibeGiroflex() {
                this.giroflexDesabilitado = false;
            },

            ocultaGiroflex() {
                this.giroflexDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.giroflex'];
            },

            exibeFiltro() {
                this.filtroDesabilitado = false;
            },

            ocultaFiltro() {
                this.filtroDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.filtro'];
            },

            exibeCortina() {
                this.cortinaDesabilitado = false;
            },

            ocultaCortina() {
                this.cortinaDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.cortina'];
            },

            exibeFaroisLaterais() {
                this.faroisLateraisDesabilitado = false;
            },

            ocultaFaroisLaterais() {
                this.faroisLateraisDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.farois_laterais'];
            },

            exibeParalama() {
                this.paralamaDesabilitado = false;
            },

            ocultaParalama() {
                this.paralamaDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.paralama'];
            },

            exibeSistemaRefrigeracao() {
                this.refrigeracaoDesabilitado = false;
            },

            ocultaSistemaRefrigeracao() {
                this.refrigeracaoDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.refrigeracao'];
            },

            exibeSOM() {
                this.sistemaSomDesabilitado = false;
            },

            ocultaSOM() {
                this.sistemaSomDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.sistema_som'];
            },

            exibeGradeFlorestal() {
                this.gradeFlorestalDesabilitado = false;
            },

            ocultaGradeFlorestal() {
                this.gradeFlorestalDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.grade_florestal'];
            },

            exibeTomada12V() {
                this.tomadaDesabilitado = false;
            },

            ocultaTomada12V() {
                this.tomadaDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.tomada_12v'];
            },

            exibeLimpadorTraseiro() {
                this.limpadorTraseiroDesabilitado = false;
            },

            ocultaLimpadorTraseiro() {
                this.limpadorTraseiroDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.limpador_traseiro'];
            },

            exibeEsguichoDianteiro() {
                this.esguichoDianteiroDesabilitado = false;
            },

            ocultaEsguichoDianteiro() {
                this.esguichoDianteiroDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.esguicho_dianteiro'];
            },

            exibeEsguichoTraseiro() {
                this.esguichoTraseiroDesabilitado = false;
            },

            ocultaEsguichoTraseiro() {
                this.esguichoTraseiroDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.esguicho_traseiro'];
            },

            exibeEsguichoDianteiroTraseiro() {
                this.esguichoDianteiroTraseiroDesabilitado = false;
            },

            ocultaEsguichoDianteiroTraseiro() {
                this.esguichoDianteiroTraseiroDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.esguicho_dianteiro'];
            },

            exibeCarregadorUSB() {
                this.carregadorDesabilitado = false;
            },

            ocultaCarregadorUSB() {
                this.carregadorDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.carregador_usb'];
            },

            exibeBancoLateral() {
                this.bancoLateralDesabilitado = false;
            },

            ocultaBancoLateral() {
                this.bancoLateralDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.banco_lateral_articulado'];
            },

            habilitaTanqueInferior() {
                this.tanqueInferiorDesabilitado = false;
            },

            desabilitaTanqueInferior() {
                this.tanqueInferiorDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.tanque_inferior'];
            },

            habilitaTanqueSuperior() {
                this.tanqueSuperiorDesabilitado = false;
            },

            desabilitaTanqueSuperior() {
                this.tanqueSuperiorDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.tanque_superior'];
            },

            habilitaTanqueTraseiro() {
                this.tanqueTraseiroDesabilitado = false;
            },

            desabilitaTanqueTraseiro() {
                this.tanqueTraseiroDesabilitado = true;
                delete this.itemsBruteModel['0.meta.opcionais.tanque_traseiro'];
            },

            openPhoto(index) {
                this.$refs.pswp.openPhoto(index);
            },

            async excluiFoto(index) {
                try {
                    this.$delete(this.photosModel, index);

                } catch (error) {
                    console.log(error);
                }
            },

			customerCustomLabel(customer) {
                let city = customer.city ? ` (${customer.city.name}/${customer.city.state.acronym})` : ``;

                return (_.get(customer,'type') === 'physical')
                    ? `${customer.name}${city} - ${customer.document}`
                    : `${customer.name}${city}, ${customer.business_name}) - ${customer.document}`;
			},

            sellerCustomLabel(seller) {
                let city = seller.city ? ` (${seller.city.name}/${seller.city.state.acronym})` : ``;

                return (_.get(seller,'type') === 'physical')
                    ? `${seller.name}${city} - ${seller.document}`
                    : `${seller.name}${city}, ${seller.business_name}) - ${seller.document}`;
            },

            resellerCustomLabel(reseller) {
                let city = reseller.city ? ` (${reseller.city.name}/${reseller.city.state.acronym})` : ``;

                return (_.get(reseller,'type') === 'physical')
                    ? `${reseller.name}${city} - ${reseller.document}`
                    : `${reseller.name}${city}, ${reseller.business_name}) - ${reseller.document}`;
            },

            indicatorCustomLabel(indicator) {
                let city = indicator.city ? ` (${indicator.city.name}/${indicator.city.state.acronym})` : ``;

                return (_.get(indicator,'type') === 'physical')
                    ? `${indicator.name}${city} - ${indicator.document}`
                    : `${indicator.name}${city}, ${indicator.business_name}) - ${indicator.document}`;
            },

			viewStockAndCost(index) {
				const vm = this;

				if (!vm.itemsBruteModel['['+index+'].product']) {
					Swal.fire(
						'Atenção!',
						'Você deve selecionar um produto primeiro!',
						'warning'
					);
					return;
				}

				vm.itemsBruteModel['['+index+'].loadingCost'] = true;
				vm.itemsBruteModel['['+index+'].loadingStock'] = true;

				this.$store.dispatch(`products/get`, vm.itemsBruteModel['['+index+'].product.id'])
					.then(function (response) {
						vm.itemsBruteModel['['+index+'].product.stock'] = response.data.stock !== null && response.data.stock !== undefined ? response.data.stock.toString() : null;
						vm.itemsBruteModel['['+index+'].product.cost'] = vm.toCurrency(response.data.cost);
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.itemsBruteModel['['+index+'].loadingStock'] = false;
						vm.itemsBruteModel['['+index+'].loadingCost'] = false;
					});
			},

			productCustomLabel({ code, name }) {
				return code ? `${code} - ${name}` : name;
			},

			//todo
			zzz() {
				if (this.order.status === 'budget') {
					if (this.order.payment_source === 'financing')
						this.order.validity = this.$store.state.auth.settings['custom_due_financed_orders'];
					else
						this.order.validity = this.$store.state.auth.settings['custom_due_budgets'];

				} else
					this.order.validity = undefined;

				if (this.order.status === 'budget')
					Push.create('A validade do orçamento foi atualizada automaticamente!', {
						silent: true
					});
			},

			itemProductSelected(index, value) {
				this.itemsBruteModel[index].product = value;
				this.itemsBruteModel[index].price = this.itemsBruteModel[index].product.price;

				if (!this.itemsBruteModel[index].quantity)
					this.itemsBruteModel[index].quantity = 1;

				if (!this.itemsBruteModel[index].discount)
					this.itemsBruteModel[index].discount = 0;

                this.productChanged();
			},
            productChanged() {
                const code = _.get(this.itemsBruteModel, `[0].product.code`);
                console.debug('productChanged', code);

                if (code) {
                    //I-SIDE. Família 1
                    if ([
                        '81.001.03',
                        '81.001.06',
                        '81.003.01',
                        '81.003.04',
                        '81.003.05',
                        '81.003.10',
                        '81.003.11',
                        '81.003.12',
                        '81.003.13',
                        '81.003.14',
                        '81.003.26',
                        '81.003.27',
                        '81.003.28',
                        '81.003.31',
                        '81.003.33',
                        '81.004.05',
                        '81.004.06',
                        '81.004.13',
                        '81.004.16',
                        '81.004.17',
                        '81.004.20',
                        '81.004.21',
                        '81.004.22',
                        '81.004.23',
                        '81.004.24',
                        '81.004.25',
                        '81.005.31',
                        '81.005.33',
                        '81.005.39',
                        '81.005.40',
                        '81.005.41',
                        '81.005.43',
                        '81.005.44',
                        '81.005.47',
                        '81.005.48',
                        '81.005.49',
                        '81.003.34',
                        '81.003.35',
                        '81.009.06'
                    ].includes(code)) {
                        console.debug('I-SIDE. Família 1');
                        this.familia = 1;
                        this.exibeISIDE();

                        //exceção paralama
                        if ([
                            '81.004.21',
                            '81.004.22',
                            '81.004.23',
                            '81.004.24',
                            '81.004.25',
                        ].includes(code)) {
                            this.exibeParalama();
                        }

                        //banco lateral
                        if ([
                            '81.001.03',
                            '81.001.06',
                            '81.003.01',
                            '81.003.04',
                            '81.003.05',
                            '81.003.10',
                            '81.003.11',
                            '81.003.12',
                            '81.003.13',
                            '81.003.14',
                            '81.003.26',
                            '81.003.27',
                            '81.003.28',
                            '81.003.31',
                            '81.003.99',
                            '81.004.05',
                            '81.004.06',
                            '81.004.13',
                            '81.004.16',
                            '81.004.17',
                            '81.004.20',
                            '81.005.31',
                            '81.005.33',
                            '81.005.39',
                            '81.005.40',
                            '81.005.43',
                            '81.005.44',
                            '81.005.49',
                            '81.003.34',
                            '81.003.35'
                        ].includes(code)) {
                            this.exibeBancoLateral();
                        } else {
                            this.ocultaBancoLateral();
                        }

                    } else {
                        this.ocultaISIDE();

                        //Cabina Trator com Opcionais Analógicos (família 3)
                        if ([
                            '81.001.01',
                            '81.001.05',
                            '81.001.07',
                            '81.001.08',
                            '81.001.11',
                            '81.002.09',
                            '81.002.23',
                            '81.002.25',
                            '81.002.26',
                            '81.002.27',
                            '81.002.28',
                            '81.002.31',
                            '81.003.07',
                            '81.003.16',
                            '81.003.18',
                            '81.003.30',
                            '81.004.02',
                            '81.004.11',
                            '81.005.06',
                            '81.005.32',
                            '81.005.35',
                            '81.005.36',
                            '81.005.37',
                            '81.005.38',
                            '81.005.42',
                            '81.006.02',
                            '81.008.01',
                            '81.008.02',
                            '81.008.03',
                            '81.009.02',
                            '81.009.05',
                            '81.010.01',
                            '81.013.01',
                            '81.013.02',
                            '81.013.03',
                            '81.001.09',
                            '81.001.10',
                        ].includes(code)) {
                            console.debug('Cabina Trator com Opcionais Analógicos (família 3)');
                            this.familia = 3;

                            this.exibeGiroflex();
                            this.exibeFiltro();
                            this.exibeCortina();
                            this.exibeFaroisLaterais();
                            this.exibeSOM();
                            this.exibeTomada12V();
                            this.exibeCarregadorUSB();
                            this.exibeLimpadorTraseiro();
                            this.exibeEsguichoDianteiro();
                            this.exibeEsguichoTraseiro();
                            this.exibeEsguichoDianteiroTraseiro();
                            this.ocultaParalama();
                            this.ocultaSistemaRefrigeracao();
                            this.ocultaGradeFlorestal();

                            //banco lateral
                            if ([].includes(code)) {
                                this.exibeBancoLateral();
                            } else {
                                this.ocultaBancoLateral();
                            }

                        //Cabina Trator com Opcionais Digitais (família 2)
                        } else if ([
                            '81.002.16',
                            '81.002.17',
                            '81.002.18',
                            '81.002.19',
                            '81.002.20',
                            '81.002.21',
                            '81.002.24',
                            '81.002.29',
                            '81.002.30',
                            '81.002.32',
                            '81.002.33',
                            '81.003.02',
                            '81.003.36',
                            '81.003.37',
                            '81.003.38',
                            '81.003.39',
                            '81.003.40',
                            '81.003.41',
                            '81.003.42',
                            '81.003.43',
                            '81.003.44',

                            '81.004.26',
                            '81.004.27',
                            '81.004.28',
                            '81.004.29',
                            '81.004.30',

                            '81.005.45',
                            '81.005.46',
                            '81.006.01',
                            '81.006.03',
                            '81.009.01',

                            '81.010.02',

                            '81.011.01',
                            '81.011.02',
                            '81.011.03',
                            '81.011.04',
                        ].includes(code)) {
                            console.debug('Cabina Trator com Opcionais Digitais (família 2)');
                            this.familia = 2;

                            this.exibeGiroflex();
                            this.exibeFiltro();
                            this.exibeCortina();
                            this.exibeFaroisLaterais();
                            this.exibeSOM();
                            this.exibeGradeFlorestal();
                            this.exibeTomada12V();
                            this.exibeCarregadorUSB();
                            this.exibeLimpadorTraseiro();
                            this.exibeEsguichoDianteiro();
                            this.exibeEsguichoTraseiro();
                            this.exibeEsguichoDianteiroTraseiro();
                            this.ocultaSistemaRefrigeracao();

                            //exceção paralama
                            if ([
                                '81.004.26',
                                '81.004.27',
                                '81.004.28',
                            ].includes(code)) {
                                this.exibeParalama();
                            } else {
                                this.ocultaParalama();
                            }

                            //banco lateral
                            if ([].includes(code)) {
                                this.exibeBancoLateral();
                            } else {
                                this.ocultaBancoLateral();
                            }

                        //Cabina para Pulverizadores (família 4)
                        } else if ([
                            '83.003.01',
                            '83.004.02',
                            '83.004.03',
                            '83.004.04',
                            '83.004.05',
                            '83.004.06',
                            '83.005.03',
                            '83.007.01',
                        ].includes(code)) {
                            console.debug('Cabina para Pulverizadores (família 4)');
                            this.familia = 4;

                            this.exibeGiroflex();
                            this.exibeCortina();
                            this.exibeEsguichoDianteiro();
                            this.exibeTomada12V();
                            this.exibeCarregadorUSB();
                            this.exibeSOM();
                            this.ocultaFiltro();
                            this.ocultaFaroisLaterais();
                            this.ocultaGradeFlorestal();
                            this.ocultaLimpadorTraseiro();
                            this.ocultaEsguichoTraseiro();
                            this.ocultaEsguichoDianteiroTraseiro();
                            this.ocultaSistemaRefrigeracao();
                            this.ocultaParalama();

                            //banco lateral
                            if ([].includes(code)) {
                                this.exibeBancoLateral();
                            } else {
                                this.ocultaBancoLateral();
                            }

                        //Cabina para Colheitadeiras (família 5)
                        } else if ([
                            '82.003.01',
                            '82.003.08',
                            '82.004.01',
                            '82.004.02',
                            '82.004.03',
                            '82.004.04',
                            '82.004.05',
                            '82.008.01',
                            '82.008.02',
                            '82.015.01',
                            '82.015.02',
                            '82.015.03',
                            '82.015.04',
                        ].includes(code)) {
                            console.debug('Cabina para Colheitadeiras (família 5)');
                            this.familia = 5;

                            this.exibeEsguichoDianteiro();
                            this.exibeSOM();
                            this.exibeTomada12V();
                            this.exibeCarregadorUSB();
                            this.exibeGiroflex();
                            this.ocultaCortina();
                            this.ocultaFiltro();
                            this.ocultaFaroisLaterais();
                            this.ocultaGradeFlorestal();
                            this.ocultaLimpadorTraseiro();
                            this.ocultaEsguichoTraseiro();
                            this.ocultaEsguichoDianteiroTraseiro();
                            this.ocultaSistemaRefrigeracao();
                            this.ocultaParalama();

                            //banco lateral
                            if ([].includes(code)) {
                                this.exibeBancoLateral();
                            } else {
                                this.ocultaBancoLateral();
                            }
                        } else {
                            alert('Não há regras para esta cabina. Entre em contato com a Engenharia!');
                            this.itemsBruteModel[0].product = null;
                        }
                    }
                }
            },
			itemProductRemoved(index) {
				this.itemsBruteModel[index].product = null;
                this.productChanged();
			},

			diffInDays(from, to) {
				const a = moment(from);
				const b = moment(to);
				return a.diff(b, 'days');
			},
			sumDaysToDate(days, date) {
				return moment(date).add(days, 'day');
			},
			dueDayChanged(value, index) {
				this.order.installments[index].due_date = this.sumDaysToDate(value, this.order.date).format('YYYY-MM-DD');
			},
			dueDateChanged(value, index) {
				this.order.installments[index].due_days = this.diffInDays(value, this.order.date);
			},

			addItem() {
				this.itemsBrute.push({
					meta: {},
					product: undefined,
					discount: 0,
					price: undefined,
					quantity: 1,
				});
			},

			removeItem(index) {
				this.$delete(this.itemsBrute, index);
			},

			removeInstallment(index) {
				this.$delete(this.order.installments, index);
			},

			addInstallment() {
				this.order.installments = this.order.installments ?? [];

				this.order.installments.push({
					due_days: undefined,
					due_date: undefined,
					due_type: 'days',
					value: undefined,
				});
			},

			salvar() {
				if (!this.confirmation) {
					Swal.fire(
						'Atenção!',
						'Você precisa concordar com os termos para poder salvar.',
						'warning'
					);
					return;
				}

				const vm = this;

				this.order.items = this.itemsBrute.map(function(item) {
					return {
						..._.pick(item, ['quantity','price','discount','meta']),
						product_id: _.get(item.product,'id')
					}
				});
				vueSet(this.order, 'meta', this.orderMeta);

                let data = sanitize(vm.order);
                // data.photos = JSON.stringify(Object.values(this.photosModel));

				vm.saving = true;
				switch (this.operation) {
					case 'create':
						this.$store.dispatch(`orders/post`, data)
							.then(function() {
								Swal.fire(
									'Sucesso!',
									`${vm.orderName} criado com sucesso!`,
									'success'
								).then(function(/*result*/) {
									// if (result.isConfirmed)
									// 	window.location = '/orders';
								});
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
								vm.saving = false;
							});
						break;

					case 'edit':
						this.$store.dispatch(`orders/patch`, {
							id: this.order.id,
							data: data
						})
							.then(function() {
								Swal.fire(
									'Sucesso!',
									`${vm.orderName} atualizado com sucesso!`,
									'success'
								);
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
								vm.saving = false;
							});
						break;
				}
			},

			productAsyncFind(keyword) {
				const vm = this;

				this.productsFinding = true;

				this.$store.dispatch(`products/index`, {
					"filter[products.name_code]": keyword,
					"filter[types]": ['other'],
					"filter[products.salable]": true,
				})
					.then(function (response) {
						vm.products = response.data.data;
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.productsFinding = false;
					});
			},

			async customerAsyncFind(keyword) {
				this.customerFinding = true;

				try {
					let response = await this.getPeople({
						"filter[people.custom]": keyword,
						"filter[people.customer]": true
					});
					this.customers = response.data.data;

				} catch (e) {
					exibeErro(e);
				}

				this.customerFinding = false;
			},

			async resellerAsyncFind(keyword) {
				this.resellerFinding = true;

				try {
					let params = {"filter[people.reseller]": true};

					if (keyword !== undefined)
						params["filter[people.name]"] = keyword;

					let response = await this.getPeople(params);
					this.resellers = response.data.data;

				} catch (e) {
					exibeErro(e);
				}

				this.resellerFinding = false;
			},

			async sellerAsyncFind(keyword) {
				this.sellerFinding = true;

				try {
					let params = {"filter[people.seller]": true};

					if (keyword !== undefined)
						params["filter[people.name]"] = keyword;

					let response = await this.getPeople(params);
					this.sellers = response.data.data;

				} catch (e) {
					exibeErro(e);
				}

				this.sellerFinding = false;
			},

			async carrierAsyncFind(keyword) {
				this.carrierFinding = true;

				try {
					let response = await this.$store.dispatch(`carriers/index`, {keyword: keyword});
					this.carriers = response.data;

				} catch (e) {
					exibeErro(e);
				}

				this.carrierFinding = false;
			},

			async indicatorAsyncFind(keyword) {
				this.indicatorFinding = true;

				try {
					let response = await this.getPeople({"filter[people.name]": keyword});
					this.indicators = response.data.data;

				} catch (e) {
					exibeErro(e);
				}

				this.indicatorFinding = false;
			},

			async contactAsyncFind(keyword) {
				this.contactFinding = true;

				try {
					let response = await this.getPeople({"filter[people.name]": keyword});
					this.contacts = response.data.data;

				} catch (e) {
					exibeErro(e);
				}

				this.contactFinding = false;
			},

			getPeople(params = {}) {
				return this.$store.dispatch(`people/index`, params);
			},

			async compatibleProductAsyncFind(equipment) {
				const vm = this;

				try {
					vm.compatibleProductsFinding = true;
					const response = await this.$store.dispatch(`products/index`, {
						"filter[compatible_with]": equipment.id,
						"filter[active]": true,
					});

					vm.compatibleProducts = response.data.data;

					/* Se só há 1 produto compatível, então já seleciona ele automaticamente. Desabilita
					* a execução no arranque (quando os dados de this.order estiverem sendo definidos, se não dá merda). */
                    if (!vm.loadingOrder) {
                        if (vm.compatibleProducts.length === 1) {
                            vueSet(vm, 'itemsBrute', [{
                                meta: {},
                                quantity: 1,
                                discount: 0,
                                product: vm.compatibleProducts[0],
                                price: !vm.itemsBruteModel[0].price ? vm.compatibleProducts[0].price : null
                            }]);

                        } else if (vm.compatibleProducts.length === 0) {
                            vueSet(vm, 'itemsBrute', []);
                            window.alert('Nenhuma cabina disponível para este equipamento agrícola.');
                        }

                        this.productChanged();
                    }

				} catch(error) {
					exibeErro(error);
				}

				vm.compatibleProductsFinding = false;
			}
		},

		created() {
			const vm = this;

            this.operation = this.$route.name === 'orders.edit' ? 'edit' : 'create';

			this.loadingCategories = true;
			this.$store.dispatch(`categories/index`, {
				"sort": "categories.name"
			})
				.then(function (response) {
					vm.categories = response.data.data.map((c) => _.pick(c,['id','name']));
				})
				.catch(function (error) {
					// handle error
					exibeErro(error);
				})
				.then(function () {
					// always executed
					vm.loadingCategories = false;
				});

			//Carrega lista completa de vendedores
			vm.sellerAsyncFind();

            if (vm.operation === 'create') {
                vm.types = vm.$store.state.auth.user.allowed_types;

                if (vm.types.length === 1)
                    vm.order.type = vm.types[0].value;

                this.order.seller_id = this.$store.state.auth.user.id;

            } else if (vm.operation === 'edit') {
				this.loadingOrder = true;
				window.axios
					.get(`/orders/${vm.$route.params.id}`)
					.then(async function (response) {
						vm.order = Object.assign(vm.order, response.data);
						vueSet(vm, 'orderMeta', Object.assign({}, vm.orderMeta, response.data.meta));

						delete vm.order.price;
						delete vm.order.total;
						delete vm.order.discount;

						vueSet(vm, 'itemsBrute', response.data.items ?? []);

						const carrier = _.get(response.data.meta, 'frete.transportadora');
						if (carrier)
							vm.carriers.push(carrier);

						if (vm.order.type === 'cabin') {
							vm.equipments = [vm.orderMetaModel['equipamento.serie']];
							await vm.compatibleProductAsyncFind(vm.orderMetaModel['equipamento.serie']);
						}

						const customer = _.get(vm.order,'customer');
						if (customer) {
							vm.customers.push(vm.order.customer);
							vm.order.customer_id = customer.id;
						}

						const reseller = _.get(vm.order,'reseller');
						if (reseller) {
							vm.resellers.push(vm.order.reseller);
							vm.order.reseller_id = reseller.id;
						}

						const contact = _.get(vm.order,'contact');
						if (contact) {
							vm.contacts.push(vm.order.contact);
							vm.order.contact_id = contact.id;
						}

                        const indicator = _.get(vm.order,'indicator');
                        if (indicator) {
                            vm.indicators.push(vm.order.indicator);
                            vm.order.indicator_id = indicator.id;
                        }
                    })
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
                        // always executed
                        vm.loadingOrder = false;
					});
			}
		},
	}
</script>

<style scoped>
    span[contenteditable=true] {
        border-bottom: 1px dashed #d1d1d1;
    }

	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.content {
			padding: 10px 20px 20px 20px !important;
		}

		table {
			table-layout: fixed;
			width: 100%;
		}

		tr.characteristics > td {
			padding-top: 10px;
		}

		table.desktop-list-content:not(.vdpTable) {
			width: 100%;
			border-collapse: separate;
			border-spacing: 0 15px;
		}

		table.desktop-list-content > tbody > tr > td {
			user-select: auto;
			background-image: linear-gradient(to right, #a7a7a7 33%, rgba(255,255,255,0) 0%);
			background-position: bottom;
			background-size: 15px 1px;
			background-repeat: repeat-x;
			padding: 10px 0;
		}
	}
</style>
