<template>
    <vue-dropzone
        @vdropzone-file-added="vdropzonefileadded"
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
    ></vue-dropzone>
</template>

<script>
    //Dropzone
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import Swal from "sweetalert2";
    import {resizeImage} from "@/helpers";

    export default {
        name: "EjDropzone",

        computed: {
            dropzoneOptions() {
                return Object.assign(
                    {},
                    this.$dropzoneOptions,
                    this.options
                );
            }
        },

        model: {
            prop: 'value',
            event: 'change'
        },

        props: {
            value: {
                type: Array,
            },
            options: {
                type: Object,
                default: () => {}
            },
            multiple: {
                type: Boolean,
                default: () => false
            },
            createThumbnail: {
                type: Boolean,
                default: () => true
            },
            resize: {
                type: Boolean,
                default: () => true
            },
            imageType: {
                type: String,
                default: () => "image/jpeg"
            },
            quality: {
                type: Number,
                default: () => 70
            },
        },

        components: {
            vueDropzone: vue2Dropzone,
        },

        methods: {
            async vdropzonefileadded(originalFile) {
                let vm = this;

                if (!vm.multiple && vm.value.length > 0) {
                    Swal.fire(
                        'Oops...',
                        'Só é aceito uma imagem!',
                        'warning'
                    );

                } else {
                    try {
                        const createImage = src => new Promise((resolve, reject) => {
                            const img = new Image();
                            img.onload = () => resolve(img);
                            img.onerror = error => reject(error);
                            img.src = src;
                        });

                        const getImageBase64 = file => new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = error => reject(error);
                        });

                        const originalBase64 = await getImageBase64(originalFile);
                        const originalImage  = await createImage(originalBase64);

                        let resized = undefined;
                        if (vm.resize)
                            resized = await resizeImage(originalImage, vm.quality, 1280, 720, vm.imageType);

                        console.log(1)

                        let thumbnail = undefined;
                        if (vm.createThumbnail)
                            thumbnail = await resizeImage(originalImage);

                        let value = {
                            title: '',
                            w: resized ? resized.image.width : originalImage.width,
                            h: resized ? resized.image.height : originalImage.height,
                            src: resized ? resized.image.src : originalImage.src,
                        };

                        if (thumbnail)
                            value["msrc"] = thumbnail.image.src;

                        vm.value.push(value);

                        this.$emit('change', vm.value);

                    } catch (error) {
                        console.log(error);
                    }
                }

                this.$refs.myVueDropzone.dropzone.removeFile(originalFile);
            },
        }
    }
</script>

<style>
    .dropzone {
        padding: 15px 20px;
    }

    .vue-dropzone {
        border-radius: 10px;
        font-family: inherit;
        background-color: #f6f6f6;
    }

    .vue-dropzone:hover {
        background-color: #f1f1f1;
    }

    .dropzone p {
        margin-bottom: 0!important;
    }
</style>

<style scoped>

</style>
