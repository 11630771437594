<template>
    <!--        @keypress="onKeypress"-->
    <!--        @paste="onPaste"-->
    <component
        :is="tag"
        :contenteditable="contenteditable"
        @input="update"
        @blur="update"
        @keydown="fwdEv"
        @keyup="fwdEv"
        @mouseenter="fwdEv"
        @mouseover="fwdEv"
        @mousemove="fwdEv"
        @mousedown="fwdEv"
        @mouseup="fwdEv"
        @auxclick="fwdEv"
        @click="fwdEv"
        @dblclick="fwdEv"
        @contextmenu="fwdEv"
        @wheel="fwdEv"
        @mouseleave="fwdEv"
        @mouseout="fwdEv"
        @select="fwdEv"
        @pointerlockchange="fwdEv"
        @pointerlockerror="fwdEv"
        @dragstart="fwdEv"
        @drag="fwdEv"
        @dragend="fwdEv"
        @dragenter="fwdEv"
        @dragover="fwdEv"
        @dragleave="fwdEv"
        @drop="fwdEv"
        @transitionstart="fwdEv"
        @transitioncancel="fwdEv"
        @transitionend="fwdEv"
        @transitionrun="fwdEv"
        @compositionstart="fwdEv"
        @compositionupdate="fwdEv"
        @compositionend="fwdEv"
        @cut="fwdEv"
        @copy="fwdEv"
        ref="element"
        v-bind="$attrs"
    ></component>
</template>

<script>
    import {format, unformat} from 'v-money/utils';

    // function replaceAll(str, search, replacement) {
    //     return str.split(search).join(replacement);
    // }

    export default {
        name: 'ContentEditable',

        props: {
            'tag': {
                type: [String],
                default: () => 'span'
            },

            'contenteditable': {
                type: Boolean,
                default: true,
            },

            'value': [String,Number],

            'money': {
                type: Boolean,
                default: () => false
            },

            'options': {
                type: Object,
                default: () => {
                    return {
                        decimal: ',',
                        thousands: '.',
                        prefix: 'R$ ',
                        suffix: '',
                        precision: 2,
                        masked: false
                    }
                }
            },

            // 'noHTML': {
            //     type: Boolean,
            //     default: true,
            // },

            // 'noNL': {
            //     type: Boolean,
            //     default: false,
            // },
        },

        mounted() {
            this.update_content(this.value);
        },

        methods: {
            setCaret(el) {
                let setSelectionRange = function () {
                    const selection = window.getSelection();
                    const range = document.createRange();

                    range.selectNodeContents(el);
                    range.collapse(false);

                    selection.removeAllRanges();
                    selection.addRange(range);
                }

                if (el === document.activeElement) {
                    setSelectionRange()
                    setTimeout(setSelectionRange, 1) // Android Fix
                }
            },

            current_content() {
                // return this.noHTML
                //     ? this.$refs.element.innerText
                //     : this.$refs.element.innerHTML;

                return this.$refs.element.innerHTML;
            },

            update_content(newcontent) {
                // if (this.noHTML) {
                //     this.$refs.element.innerText = newcontent;
                // } else {
                    this.$refs.element.innerHTML = newcontent;
                // }
            },

            update(event) {
                if (this.money) {
                    let el = event.target;
                    let unformated = unformat(el.innerText, this.options.precision);

                    el.innerText = format(unformated, this.options)
                    this.setCaret(el);
                }

                this.$emit('input', this.current_content());
            },

            // onPaste(event) {
            //     event.preventDefault();
            //     let text = (event.originalEvent || event).clipboardData.getData('text/plain');
            //     if (this.noNL) {
            //         text = replaceAll(text, '\r\n', ' ');
            //         text = replaceAll(text, '\n', ' ');
            //         text = replaceAll(text, '\r', ' ');
            //     }
            //     window.document.execCommand('insertText', false, text);
            //     this.fwdEv(event)
            // },

            // onKeypress(event) {
            //     if (event.key == 'Enter' && this.noNL) {
            //         event.preventDefault();
            //         this.$emit('returned', this.current_content);
            //     }
            //     this.fwdEv(event)
            // },

            fwdEv(event) {
                this.$emit(event.type, event);
            }
        },

        watch: {
            value(newval/*, oldval*/) {
                if (newval != this.current_content()) {
                    this.update_content(newval);
                }
            }
        },
    }
</script>

<style scoped>

</style>
